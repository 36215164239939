import request from './request.js';
//查询配置信息
export function select_all(params) {
    return request({
        url: '/system-user/login/select-all',
        method: 'GET',
        params
    })
}
export function add_or_update(data) {
    return request({
        url: '/system-user/login/add-or-update',
        method: 'post',
        data: data
    })
}
export function find_by_id(data) {
    return request({
        url: '/system-user/login/find-by-id',
        method: 'get',
        params: data
    })
}

export function update_status(data) {
    return request({
        url: '/system-user/login/update-status',
        method: 'get',
        params: data
    })
}